import React, { Fragment } from "react";

import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Tabs, Tab, Nav } from "react-bootstrap";

import TradeHistoryTable from "../table/tradehistory";

const Transactions = () => {
  const tabData = [
    {
      name: "Open Orders",
      icon: "home",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "Trades History",
      icon: "user",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Contact",
      icon: "phone",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },

    {
      name: "Message",
      icon: "envelope",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
  ];

  return (
    <Fragment>
      <PageTitle activeMenu="Transactions" motherMenu="Dashboard" pageContent="Trade Details" />
      <Row>
        <div className="col-xl-12">
          <Card>
            {/* <Card.Header>
              <Card.Title>Transaction Details</Card.Title>
            </Card.Header> */}
            <Card.Body>
            <Tabs defaultActiveKey="trades-history" id="uncontrolled-tab-example" className="mb-3">
              <Tab eventKey="open-orders" title="Open Orders">
              <blockquote>
                    <small>
                      {" "}
                      No {" "}
                      <cite title="Open Trade ">Open Trade</cite>
                      {" "}Found
                    </small>
                  </blockquote>
                  <p className="text-muted">
                      Please place some Limit Orders.
                    </p>
              </Tab>
              <Tab eventKey="trades-history" title="Trades History">
                  <TradeHistoryTable />
              </Tab>
            </Tabs>
            </Card.Body>
          </Card>
        </div>
      </Row>
    </Fragment>
  );
};

export default Transactions;
