import React, { useState, useRef, useEffect } from "react";
import { Table, Dropdown } from "react-bootstrap";
import SimpleDateTime  from 'react-simple-timestamp-to-date';

import { Link } from "react-router-dom";
// import data from "./tableData.js";

import {getAllTrades} from '../../services/getAccInfo';

const TradeHistoryTable = () => {
  useEffect(() => {
    //Get All Trade Data
    getTradeData();  
  }, []);

    //  API Calling Starts
    const getTradeData = async () => {
    const data = await getAllTrades();

    //console.log('All Data is '+data);
    
    var stage1 = JSON.stringify(data);
    var stage2 = JSON.parse(stage1);     
    let allTradesData = [];   
    for(var i in stage2)
    {
        for(var j in stage2[i])
        {
            var t1 = JSON.stringify(stage2[i][j]);
            var t2 = JSON.parse(t1);
            // allTradesData.push("{'"+t2.symbol+"','"+t2.price+"','"+t2.executedQty+"','"+t2.status+"','"+t2.type+"','"+t2.side+"','"+t2.stopPrice+"','"+t2.updateTime+"'}");
            allTradesData.push(t2);
          }
    }     

    setAlltrades(allTradesData);
  };
  //  API Calling Ends

  const [data, setData] = useState(
    document.querySelectorAll("#historyTable_basic_table tbody tr")
  );
  const sort = 10;
  const activePag = useRef(0);
  const [test, settest] = useState(0);

  const [alltrades, setAlltrades] = useState([]);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#historyTable_basic_table tbody tr"));
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  
  return (
    <div className="col-12">
      <div className="card">
        {/* <div className="card-header">
          <h4 className="card-title">Patient</h4>
        </div> */}
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="historyTable_basic_table" className="dataTables_wrapper">
              <table
                id="example5"
                className="display dataTable no-footer w-100"
                style={{ minWidth: 845 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row"> 
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Sr: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Sr.
                    </th>                   
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Trade Date: activate to sort column ascending"
                      style={{ width: 70 }}
                    >
                      Trade Date
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Coin: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Coin
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Side: activate to sort column ascending"
                      style={{ width: 120 }}
                    >
                      Side
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Size: activate to sort column ascending"
                      style={{ width: 62 }}
                    >
                      Size
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="OrderType: activate to sort column ascending"
                      style={{ width: 106 }}
                    >
                      Order Type
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="OrderPrice: activate to sort column ascending"
                      style={{ width: 66 }}
                    >
                      Order Price
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="TriggerPrice: activate to sort column ascending"
                      style={{ width: 47 }}
                    >
                      Trigger Price
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status: activate to sort column ascending"
                      style={{ width: 47 }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                {alltrades.map((item, idx) => (
                  <tr role="row" className="odd">     
                    <td>{idx+1}</td>               
                    <td><SimpleDateTime dateSeparator="/" timeSeparator=":" dateFormat="DMY" meridians="1">{(alltrades[idx].updateTime)/1000}</SimpleDateTime></td>
                    <td>{alltrades[idx].symbol}</td>
                    <td>{alltrades[idx].side}</td>
                    <td>{parseFloat(alltrades[idx].executedQty)}</td>
                    <td>
                      <span className="badge light badge-danger">
                        <i className="fa fa-circle text-danger mr-1" />
                        {alltrades[idx].type}
                      </span>
                    </td>
                    <td>{parseFloat(alltrades[idx].price)}</td>
                    <td>{parseFloat(alltrades[idx].stopPrice)}</td>
                    <td>{alltrades[idx].status}</td>
                  </tr>   
                  ))}                             
                </tbody>
              </table>

              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/transactions"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    Previous
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/transactions"
                        className={`paginate_button  ${
                          activePag.current === i ? "current" : ""
                        } `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/transactions"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    Next
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeHistoryTable;
