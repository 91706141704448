import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import MrkCap from "../tradingview/marketcapital";

const MarketCapital = () => {
    return (
    <>      
      <div className="row">
        <div className="col-xl-12">
            <MrkCap />  
        </div>
      </div>
    </>
  );
};

export default MarketCapital;
