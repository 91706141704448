import loadable from "@loadable/component";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Decimal } from "mssql";
import pMinDelay from "p-min-delay";
import Nouislider from "nouislider-react";
import React, { Fragment, useContext, useState, useEffect } from "react";

import DateRangePicker from "react-bootstrap-daterangepicker";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { getLatestPrice } from "../../services/getCurrency";
import doSimpleTrade from "../../services/simpleTrading";
import { ButtonGroup, SplitButton, Form, Badge, Popover, OverlayTrigger, Button, Dropdown, Nav, Tab, Card } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import swal from "sweetalert";
import ClickAblePips from "../Noui Slider/ClickablePips";

import Select from "react-select";
import getExchangeInfo from "../../services/getMarketData";
import accApiCall from '../../services/getAccInfo';
import Helmet from 'react-helmet';
import TradingViewWidget from 'react-tradingview-widget';
import MyTradingView from '../tradingview/tvwidget';
import { getFreeLocked } from '../../services/getAccInfo';
import swalMessage from "@sweetalert/with-react";


const SimpleTrade = () => {
  const tabData = [
    {
      name: "Smart Trade",
      icon: "home",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "Smart Sell",
      icon: "user",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Smart Cover",
      icon: "phone",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },

    {
      name: "Smart Buy",
      icon: "envelope",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
  ];

  let width = window.innerWidth; // For Popover
  const [currencyPrices, setCurrencyPrices] = useState([]);

  const [activeExchange, setExchange] = useState("binance");
  const [activeMarket, setMarket] = useState("");
  const [pair, setPair] = useState([]);

  const [bPrice, setBPrice] = useState(0);
  const [bUnit, setBUnit] = useState(0);
  const [bTotal, setBTotal] = useState(0);

  const [sPrice, setSPrice] = useState(0);
  const [sUnit, setSUnit] = useState(0);
  const [sTotal, setSTotal] = useState(0);

  const [tradeType, setTradeType] = useState("MARKET");
  const [tradeTypeS, setTradeTypeS] = useState("MARKET");

  const [myExchangeInfo, setExchangeInfo] = useState(null);

  const [buySlider, setBuySlider] = useState(0);
  const [sellSlider, setSellSlider] = useState(0);

  const [accDtls, setAccDtls] = useState([]);

  const [baseAsset, setBaseAsset] = useState('');
  const [quoteAsset, setQuoteAsset] = useState('');
  
  const [ baseFree, setBaseFree] = useState('');
  const [ quoteFree, setQuoteFree] = useState('');
  const [ baseLocked, setBaseLocked] = useState('');
  const [ quoteLocked, setQuoteLocked] = useState('');

  const [showTV, setShowTV] = useState(true);
  
  function calculateTotalBuy(e) {
    
    //console.log(e.target.id+'<>'+e.target.value);
    if (e.target.id == "buy_unit")
      setBTotal(parseFloat(e.target.value * bPrice));
    else if (e.target.id == "buy_price")
      setBTotal(parseFloat(bUnit * e.target.value));
  }
  function calculateTotalSell(e) {
    //console.log(e.target.id+'<>'+e.target.value);
    if (e.target.id == "sell_unit")
      setSTotal(parseFloat(e.target.value * sPrice));
    else if (e.target.id == "sell_price")
      setSTotal(parseFloat(sUnit * e.target.value));
  }

  const handleDropdowns = async (e) => {
    //alert(e.target.value);

    if(e.target.value=='select')
    {
      setBPrice(0);
      setSPrice(0);
      setBaseAsset('select');
      setQuoteAsset('select');

      setBaseFree(0);
      setBaseLocked(0);
      setQuoteFree(0);
      setQuoteLocked(0);
    }
    else
    {
        var newStr = e.target.value.replace('/', '');
        const rtn = await getLatestPrice(newStr);
        var as = JSON.parse(JSON.stringify(rtn));
        //alert(as.data.price);
        await setBPrice(parseFloat(as.data.price));
        await setSPrice(parseFloat(as.data.price));

        const curSize = await getFreeLocked(e.target.value);
        console.log('Cur Size: '+curSize);

        var obj = JSON.parse(curSize);
        // console.log("Raw Base Assest: "+obj.baseAsset+" <> "+obj.free+" <> "+obj.locked);
        // console.log("Raw Quote Assest: "+obj.quoteAsset+" <> "+obj.free1+" <> "+obj.locked1); 

        setBaseAsset(obj.baseAsset);
        setQuoteAsset(obj.quoteAsset);        
        setBaseFree(obj.free);
        setBaseLocked(obj.locked);
        setQuoteFree(obj.free1);
        setQuoteLocked(obj.locked1);
        
        // console.log("Base Assest: "+baseAsset+" <> "+baseFree+" <> "+baseLocked);
        // console.log("Quote Assest: "+quoteAsset+" <<>> "+quoteFree+" <<>> "+quoteLocked);        
    }
  };

  //  API Calling Starts
  const fireSimpleBuyTrade = async () => {
    let isValid = validateTrade('B');

    if(isValid!=true)
        return false;
        
    var pairStr = pair.replace('/', '');
    var dataQueryString =
      "symbol=" + pairStr + "&side=BUY&type=" + tradeType + "&quantity=" + bUnit;
    if (tradeType === "LIMIT") {
      //IF LIMIT then timeInForce & Price is compulsary
      dataQueryString = dataQueryString + "&timeInForce=GTC&price=" + bPrice;
    }
    const data = await doSimpleTrade(dataQueryString);
    console.log(data);
    swal("Well Done", "Trade successful", "success");
    clearForm();
  };

  const fireSimpleSellTrade = async () => {
    let isValid = validateTrade('S');
    
    if(isValid!=true)
        return false;

    var pairStr = pair.replace('/', '');
    var dataQueryString =
      "symbol=" + pairStr + "&side=SELL&type=" + tradeTypeS + "&quantity=" + sUnit;
    if (tradeType === "LIMIT") {
      //IF LIMIT then timeInForce & Price is compulsary
      dataQueryString = dataQueryString + "&timeInForce=GTC&price=" + sPrice;
    }    
    const data = await doSimpleTrade(dataQueryString);
    console.log(data);
    swal("Well Done", "Trade successful", "success");
    clearForm();
  };
  //  API Calling Ends

  function clearForm()
  {
      setPair('select');
      setBUnit(0);
      setBPrice(0);
      setBTotal(0);
      setSUnit(0);
      setSPrice(0);
      setSTotal(0);
      
  }

  function validateTrade(side)
  {
      let trpair = pair;
      if(trpair=='' || trpair=='select'|| trpair==null )
      {
        swal("Oops", "Please select trading pair", "error");
        return false;
      }
      else if(side=='B'){
        if(Number(bUnit)<=0)
        {
            swal("Oops", "Units can not be zero or negative", "error");
            return false;
        }
        if(Number(bPrice)<=0)
        {
            swal("Oops", "Price can not be zero or negative", "error");
            return false;
        }
        if(Number(bTotal)>Number(quoteFree))
        {
            swal("Oops", "Insufficient funds", "error");
            return false;
        } 
      }
      else{
        // alert(sPrice+' == '+ sUnit +' == '+ sTotal +' == '+ baseFree);  
        if(Number(sUnit)<=0)
        {
            swal("Oops", "Units can not be zero or negative", "error");
            return false;
        }    
        if(Number(sPrice)<=0)
        {
            swal("Oops", "Price can not be zero or negative", "error");
            return false;
        } 
        if(Number(sUnit)>Number(baseFree))
        {
            swal("Oops", "Insufficient balance", "error");
            return false;
        }
      }
      return true;
  }
  
  const getTradingPairs = async () => {
    // Uncomment this to use this data to populate pair drop-down with live data instead of hard-coded.
    // const data = await getExchangeInfo();
    // await setExchangeInfo(data);
    
    //   var temp = JSON.stringify(myExchangeInfo);
    //   var obj = JSON.parse(temp);
    //   var symbols = obj.data.symbols;
    //   symbols.map((key, value) => {
    //       console.log(key.symbol+" <> "+key.baseAsset+"/"+key.quoteAsset);
    //   });
  };
  
  //  API Calling Starts
  const fetchAccount = async () =>{
      const response = await accApiCall();
      await setAccDtls(response);
  };

  useEffect(() => {
    //Get Trading Pairs
    getTradingPairs();    
    //Get account info to get free/lock balance of selected trading pair
    //fetchAccount();
    if(!accDtls)
    {
        return false;
    } 
  }, []);
  
  if(accDtls!=null && accDtls!='')
  {
      var stage1 = JSON.stringify(accDtls);  
      console.log('Data Returned at stage1 ---'+stage1);
      var stage2 = JSON.parse(stage1);
      console.log('Data Returned at stage2 ---'+stage2);
      var stage3 = JSON.stringify(stage2.data.balances);
      console.log('Data Returned at stage3 ---'+stage3);
      var stage4 = JSON.parse(stage3);
      console.log('Data Returned at stage4 ---'+stage4);
      
      var curPrcArr = [];
      for (var i = 0; i < stage4.length; i++) {
        curPrcArr.push([stage4[i].asset,stage4[i].free,stage4[i].locked]);
      };      
      setCurrencyPrices(curPrcArr);
  }
  //  API Calling Ends

  function handleBuySlider(val){
    // get val% of quoteFree & assign to bTotal    
    console.log('quoteFree: '+quoteFree+' @#@ val: '+val);
    // var num1 = ((Number(quoteFree))*(Number(val)))/100;
    // console.log(num1);
    // setBTotal(num1);
    // // get Total/Price & assign to Units
    // console.log('OMG: '+bPrice);
    // var num2 = num1/Number(bPrice);
    // setBUnit(num2);
    // console.log("Num2: " + num2);  
  };

  function handleSellSlider(val1) {    
    console.log("Sell>>> " + val1);  
  };

  function showBaseSize(){
    if(baseAsset=='' || baseAsset==null || quoteAsset=='' || quoteAsset==null)
    {
        swal("Oops", "Please select Trading Pair", "error");
        return false;
    }
    swalMessage(
      <div>
        <h2>{baseAsset}</h2>
        <p>Free: {baseFree}</p>
        <p>Locked: {baseLocked}</p>
      </div>
    )
  }

  function showQuoteSize(){
    if(baseAsset=='select' || quoteAsset=='select')
    {
        swal("Oops", "Please select Trading Pair", "error");
        return false;
    }
    swalMessage(
      <div>
        <h2>{quoteAsset}</h2>
        <p>Free: {quoteFree}</p>
        <p>Locked: {quoteLocked}</p>
      </div>
    )
  }

  function showHideTV(e) {
      //alert(e.target.checked);
      if(e.target.checked==false)
      {

      }
  }

  const { background } = useContext(ThemeContext);
  return (
    <Fragment>
      <div className="page-titles">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">
            <Link to="/coin-details">Trading</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/regular-trade">Traditional Trade</Link>
          </li>
        </ol>
      </div>
      <div className="custom-control custom-switch toggle-switch text-left mr-4 mb-2">
        <input
            type="checkbox"
            className="custom-control-input"
            id="tradingswitch"
            onChange={(e) => {
              setShowTV(e.target.checked);
                  showHideTV(e);
                }}
        />
        <label
            className="custom-control-label fs-14 text-black pr-2"
            htmlFor="tradingswitch"
        >
        Trading View
        </label>
      </div>
        {
            showTV ? <MyTradingView /> : null
        }

      <div className="row">
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-body">
              <label>Exchange</label>
              <select
                defaultValue={"option"}
                className="form-control"
                onChange={(e) => {
                  setExchange(e.target.value);
                  handleDropdowns(e);
                }}
              >
                <option value="binance">Binance</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="card">
            <div className="card-body">
              <label>Trading Pair</label>
              <select
                className="form-control"
                id="pairs"
                onChange={(e) => {
                  setPair(e.target.value);
                  handleDropdowns(e);
                }}
              >
                <option value={"select"}>Select</option>
                <option value={"BTC/USDT"}>BTCUSDT</option>
                <option value={"BNB/USDT"}>BNBUSDT</option>
                <option value={"ETH/USDT"}>ETHUSDT</option>
                <option value={"LTC/USDT"}>LTCUSDT</option>
                <option value={"TRX/USDT"}>TRXUSDT</option>
                <option value={"XRP/USDT"}>XRPUSDT</option>
                <option value={"BTC/BUSD"}>BTCBUSD</option>
                <option value={"BNB/BUSD"}>BNBBUSD</option>
                <option value={"ETH/BUSD"}>ETHBUSD</option>
                <option value={"LTC/BUSD"}>LTCBUSD</option>
                <option value={"TRX/BUSD"}>TRXBUSD</option>
                <option value={"XRP/BUSD"}>XRPBUSD</option>
                <option value={"BNB/BTC"}>BNBBTC</option>
                <option value={"ETH/BTC"}>ETHBTC</option>
                <option value={"LTC/BTC"}>LTCBTC</option>
                <option value={"TRX/BTC"}>TRXBTC</option>
                <option value={"XRP/BTC"}>XRPBTC</option>
                <option value={"LTC/BNB"}>LTCBNB</option>
                <option value={"TRX/BNB"}>TRXBNB</option>
                <option value={"XRP/BNB"}>XRPBNB</option>
              </select>
            </div>
          </div>
        </div>        
        <div className="col-xl-12 col-lg-12">
        <div className="form-group row">
          <div className="col-lg-12">
          <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                  <Nav as="ul" className="nav-pills mb-4 justify-content-end">
                    {tabData.map(
                      (data, i) =>
                        i !== tabData.length && (
                          <Nav.Item as="li" key={i}>
                            <Nav.Link eventKey={data.name.toLowerCase()}>
                              {data.name}
                            </Nav.Link>
                          </Nav.Item>
                        )
                    )}
                  </Nav>
                  <Tab.Content className="pt-4">
                    <Tab.Pane eventKey={'smart trade'} key={'0'}>
                    <div className="col-xl-9 col-lg-9">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Smart Trade</h4>              
                          <h5 className="text-muted">{quoteFree}  {quoteAsset}</h5>                            
                          <i className="fa fa-question-circle" onClick={showQuoteSize}></i>                      
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Units</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="buy_unit"
                                  name="buy_unit"
                                  value={bUnit}
                                  onChange={(e) => {
                                    setBUnit(e.target.value);
                                    calculateTotalBuy(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Price</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="buy_price"
                                  name="buy_price"
                                  value={bPrice}
                                  onChange={(e) => {
                                    setBPrice(e.target.value);
                                    calculateTotalBuy(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Total</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  id="buy_total"
                                  name="buy_total"
                                  value={bTotal}
                                />
                              </div>
                            </div>
                            <fieldset className="form-group">
                              <div className="row">
                                <label className="col-form-label col-sm-3 pt-0">Type</label>
                                <div className="col-sm-9">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="MARKET"
                                      defaultChecked
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Market</label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="LIMIT"
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Limit</label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="MARKET"
                                      defaultChecked
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Market</label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="pip-position mb-5">
                              <div id="pips-positions-stepped">
                                <Nouislider
                                  id="buy"
                                  start={0}
                                  pips={{ mode: "count", values: 5 }}
                                  clickablePips
                                  range={{
                                    min: 0,
                                    max: 100,
                                  }}
                                  // tooltips
                                  onSlide={(data) => handleBuySlider(data)}
                                  step={25}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={fireSimpleBuyTrade}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  
                      </div> 
                      <div className="col-xl-9 col-lg-9">
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Take Profit</h4>              
                          <h5 className="text-muted">{quoteFree}  {quoteAsset}</h5>                            
                          <i className="fa fa-question-circle" onClick={showQuoteSize}></i>                      
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Units</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="buy_unit"
                                  name="buy_unit"
                                  value={bUnit}
                                  onChange={(e) => {
                                    setBUnit(e.target.value);
                                    calculateTotalBuy(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Price</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="buy_price"
                                  name="buy_price"
                                  value={bPrice}
                                  onChange={(e) => {
                                    setBPrice(e.target.value);
                                    calculateTotalBuy(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Total</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  id="buy_total"
                                  name="buy_total"
                                  value={bTotal}
                                />
                              </div>
                            </div>
                            <fieldset className="form-group">
                              <div className="row">
                                <label className="col-form-label col-sm-3 pt-0">Type</label>
                                <div className="col-sm-9">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="MARKET"
                                      defaultChecked
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Market</label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="LIMIT"
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Limit</label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="MARKET"
                                      defaultChecked
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Market</label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="pip-position mb-5">
                              <div id="pips-positions-stepped">
                                <Nouislider
                                  id="buy"
                                  start={0}
                                  pips={{ mode: "count", values: 5 }}
                                  clickablePips
                                  range={{
                                    min: 0,
                                    max: 100,
                                  }}
                                  // tooltips
                                  onSlide={(data) => handleBuySlider(data)}
                                  step={25}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={fireSimpleBuyTrade}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  
                      </div>
                    <div className="col-xl-9 col-lg-9"> 
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">Stop Loss</h4>              
                          <h5 className="text-muted">{quoteFree}  {quoteAsset}</h5>                            
                          <i className="fa fa-question-circle" onClick={showQuoteSize}></i>                      
                        </div>
                        <div className="card-body">
                          <div className="basic-form">
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Units</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="buy_unit"
                                  name="buy_unit"
                                  value={bUnit}
                                  onChange={(e) => {
                                    setBUnit(e.target.value);
                                    calculateTotalBuy(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Price</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=""
                                  id="buy_price"
                                  name="buy_price"
                                  value={bPrice}
                                  onChange={(e) => {
                                    setBPrice(e.target.value);
                                    calculateTotalBuy(e);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label">Total</label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  id="buy_total"
                                  name="buy_total"
                                  value={bTotal}
                                />
                              </div>
                            </div>
                            <fieldset className="form-group">
                              <div className="row">
                                <label className="col-form-label col-sm-3 pt-0">Type</label>
                                <div className="col-sm-9">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="MARKET"
                                      defaultChecked
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Market</label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="LIMIT"
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Limit</label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="buyRadios"
                                      value="MARKET"
                                      defaultChecked
                                      onChange={(e) => {
                                        setTradeType(e.target.value);
                                      }}
                                    />
                                    <label className="form-check-label">Market</label>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="pip-position mb-5">
                              <div id="pips-positions-stepped">
                                <Nouislider
                                  id="buy"
                                  start={0}
                                  pips={{ mode: "count", values: 5 }}
                                  clickablePips
                                  range={{
                                    min: 0,
                                    max: 100,
                                  }}
                                  // tooltips
                                  onSlide={(data) => handleBuySlider(data)}
                                  step={25}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <div className="col-sm-12">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={fireSimpleBuyTrade}
                                >
                                  Confirm
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>    
                      </div> 
                    </Tab.Pane>
                    <Tab.Pane eventKey={'smart sell'} key={'1'}>
                            <p>Smart Sell</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'smart cover'} key={'2'}>
                            <p>Smart Cover</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey={'smart buy'} key={'3'}>
                            <p>Smart Buy</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </div>
        </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SimpleTrade;
