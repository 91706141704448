import { React, useState, useEffect, Fragment } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import profile from "../../../images/profile/5.jpg";
import LineChart from "../kripton/Portofolio/LineChart1";

 import accApiCall from '../../services/getAccInfo-new';
import swal from "sweetalert";
import { AccordionDetails } from "@material-ui/core";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,  
  ProgressBar,
} from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";

var latestBalances = [];

const Portofolio = () => {  

  const [accDtls, setAccDtls] = useState([]);
  const [balances, setBalances] = useState([]);

  useEffect(() => {
      const fetchData = async () =>{
          const response = await accApiCall();
          await setAccDtls(response);
      };
     
      fetchData();
  }, []);

  var stage1 = JSON.stringify(accDtls);
  //console.log('Stage1: '+stage1);
  //console.log('------0--------');
  var stage2 = JSON.parse(stage1);
  //console.log('Stage2: '+stage2);
  //console.log('------00000000--------');

  var res = [];              
  for(var i in stage2)
  {
      //console.log(stage2[i]);
      res.push(stage2[i]);
  }
  if(typeof res[0] === 'undefined' || res[0] === null)
  {
      //wait only
  }
  else
  {
    latestBalances = [];
    for(var i in res[0].balances)
    {
      latestBalances.push(res[0].balances[i]);
      //console.log(res[0].balances[i].asset+" == "+res[0].balances[i].free+" == "+res[0].balances[i].locked);
    }
   // setBalances(latestBalances);//getting error or rerendering
  }
  console.log(latestBalances);

  return (
    <Fragment>
      <PageTitle activeMenu="Portofolio" motherMenu="Dashboard" />
      <Row> 
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Coin Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>Token</th>                    
                    <th>Amount/Unit(Free)</th>
                    <th>Amount/Unit(Locked)</th>
                  </tr>
                </thead>
                <tbody>
                  {latestBalances.map((item, idx) => (
                  <tr>
                    <td>{latestBalances[idx].asset}</td>
                    <td>
                      <Badge variant="primary light">{parseFloat(latestBalances[idx].free)}</Badge>
                    </td>
                    <td><Badge variant="danger light">{parseFloat(latestBalances[idx].locked)}</Badge></td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Portofolio;
