import React, { useContext, useState, useMemo  } from "react";

/// React router dom
import { BrowserRouter as Router, Switch } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

import { UserContext } from './hooks/UserContext';

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Home2 from "./components/Dashboard/Home2";
import CoinDetails from "./components/Dashboard/CoinDetails";
import MyWallets from "./components/Dashboard/MyWallets";
import Transactions from "./components/Dashboard/Transactions";
import Portofolio from "./components/Dashboard/Portofolio";
import MarketCapital from "./components/Dashboard/MarketCapital";
import SimpleTrade from "./components/Dashboard/SimpleTrade";
import SmartTrade from "./components/Dashboard/SmartTrade";

///MyExchanges
import Exchange from './components/myexchange/exchanges';

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login"; 
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import { ThemeContext } from "../context/ThemeContext";

import PublicRoute from './hooks/PublicRoute';
import PrivateRoute from './hooks/PrivateRoute';

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const [user, setUser] = useState(null);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);



  const privateroutes = [    
    /// Add all routes which requirs authentication
    { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: Home2 },
    { url: "coin-details", component: CoinDetails },
    { url: "my-wallets", component: MyWallets },
    { url: "transactions", component: Transactions },
    { url: "portofolio", component: Portofolio },
    { url: "market-capital", component: MarketCapital },
    { url: "regular-trade", component: SimpleTrade },
    { url: "smart-trade", component: SmartTrade },
    { url: "connect-exchange", component: Exchange },
  ];

  const publicroutes = [
    ///  Add all routes which DOESN'T requirs authentication
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ];

  let path = window.location.pathname;
  
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = false;
  if(path === "" || path.split("-").includes("page"))
  {
      pagePath = true;
  }

  return (
    <Router>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
          <UserContext.Provider value={value}>
                <Switch>
                  <PublicRoute restricted={true} component={Login} path="/" exact />
                  {publicroutes.map((data, i) => (
                    <PublicRoute
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}

                  {privateroutes.map((data, i) => (
                    <PrivateRoute
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
            </UserContext.Provider>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </Router>
  );
};

export default Markup;
