import axios from 'axios'

const bnnCfg = require('../../config/binance.js');

var baseURL='http://crypto.procezio.in:5000';

export default async function getCurrencyPrices() {

    const options = {
      url: baseURL + bnnCfg.endpoints.allTickers,        
      method: 'GET',
    }; 
    var valuesArray = '';
    await axios(options)
      .then(response => { 
        // valuesArray = JSON.stringify(response);
        valuesArray = response;
        // alert('Response: '+valuesArray);     
        console.log('Tickers : '+valuesArray);
      })
      .catch(error => {console.log('Error@getCurrencyPrices: '+error)});

    return valuesArray;
  }

  async function getLatestPrice(pair) {
    const options = {
      url: baseURL + bnnCfg.endpoints.tickerPrice+"?symbol="+pair,      
      method: 'GET',
    }; 
    var valuesArray = '';
    await axios(options)
      .then(response => { 
        valuesArray = response;
        console.log('Latest Price : '+valuesArray);
      })
      .catch(error => {console.log('Error@getLatestPrice: '+error)});

    return valuesArray;
  }

  async function get24HrPriceChange() {
    const options = {    
        url: baseURL + bnnCfg.endpoints.ticker24Change,
        method: 'GET',
    }; 
    var valuesArray = '';
    await axios(options)
      .then(response => { 
        // valuesArray = JSON.stringify(response);
        valuesArray = response;
        for (const [key, value] of Object.entries(response)) {
          //if(key=='BTCUSDT' || key=='ETHUSDT' || key=='LTCUSDT' || key=='DASHUSDT')
            //console.log(`${key}: ${value}`);
        }

        // alert('Response: '+valuesArray);     
        console.log('Tickers : '+valuesArray);
      })
      .catch(error => {console.log('Error@get24HrPriceChange: '+error)});

    return valuesArray;
  }

  

  export { 
    get24HrPriceChange, getLatestPrice
  };