import TradingViewWidget,  {Themes, AdvancedRealTimeChart} from 'react-tradingview-widget';

const MyTradingView = () => {
    return (
    <TradingViewWidget 
        width= '980'
        height= '610'
        symbol= 'BITSTAMP:BTCUSD'
        interval= "D"
        timezone= "Etc/UTC"
        theme= "light"
        style= "1"
        locale= "in"
        toolbar_bg= "#f1f3f6"
        withdateranges= 'true'
        allow_symbol_change= {true}
        details= {true}
        container_id= "tradingview_952bf"
    />  
    // <AdvancedRealTimeChart theme="light" autosize></AdvancedRealTimeChart>

    )
};

export default MyTradingView;
