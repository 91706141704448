import axios from 'axios'
const bnnCfg = require('../../config/binance.js');
const crypto = require('crypto');

export default async function getExchangeInfo() {
  var currentTimeInMilliseconds=Date.now();
  var defaultAccount = localStorage.getItem('defaultAccount');
    console.log('Default Account@getExchangeInfo is :'+defaultAccount);


    const keys = {
      'akey' : defaultAccount=='P' ? bnnCfg.testnet_env.api_key: bnnCfg.live_env.api_key,
      'skey' : defaultAccount=='P' ? bnnCfg.testnet_env.api_secret:bnnCfg.live_env.api_secret,
    }
    const baseURL = defaultAccount=='P' ? bnnCfg.testnet_env.base_url: bnnCfg.live_env.base_url;
    const options = {
        // url: 'https://testnet.binance.vision/api/v3/ticker/price',        
        url: bnnCfg.endpoints.exchangeInfo,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-MBX-APIKEY': keys['akey'],
        },
    }; 
    var valuesArray = '';
    await axios(options)
      .then(response => { 
        valuesArray = response;
      })
      .catch(error => {console.log('Error@getExchangeInfo: '+error)});

    return valuesArray;
  }