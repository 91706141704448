/* eslint-disable jsx-a11y/role-supports-aria-props */
/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import book from "../../../images/book.png";
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
	
	//sidebar icon Heart blast
	var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
      "dashboard-light",
      "dashboard-dark",
      "coin-details",
      "my-wallets",
      "transactions",
      "portofolio",
      "market-capital",
    ],
    trading = [
      "regular-trade",
      "smart-trade",
      "terminal",
    ],
    myexchanges = [
      "connect-exchange",
    ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul aria-expanded="false">
              <li>
                <Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="dashboard">
                  Dashboard
                </Link>
              </li>
              {/* <li>
                <Link
                  className={`${path === "dashboard-dark" ? "mm-active" : ""}`}
                  to="/dashboard-dark"
                >
                  Dashboard Dark
                </Link>
              </li> */}
              {/* <li>
                <Link
                  className={`${path === "coin-details" ? "mm-active" : ""}`}
                  to="/coin-details"
                >
                  Coin Details
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "my-wallets" ? "mm-active" : ""}`}
                  to="/my-wallets"
                >
                  My Wallets
                </Link>
              </li> */}
              <li>
                <Link
                  className={`${path === "transactions" ? "mm-active" : ""}`}
                  to="/transactions"
                >
                  Transactions
                </Link>
              </li> 
              <li>
                <Link
                  className={`${path === "portofolio" ? "mm-active" : ""}`}
                  to="/portofolio"
                >
                  Portofolio
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "market-capital" ? "mm-active" : ""}`}
                  to="/market-capital"
                >
                  Market Capital
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${myexchanges.includes(path) ? "mm-active" : ""}`}>
            <Link to="connect-exchange" className="ai-icon" aria-expanded="false">
              <i className="flaticon-381-unlocked-2"></i>
              <span className="nav-text">My Exchanges</span>
            </Link>
          </li>

          <li className={`${trading.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
              <i className="flaticon-381-news"></i>
              <span className="nav-text">Trading</span>
            </Link>
            <ul aria-expanded="false">              
              <li>
                <Link
                  className={`${path === "simple-trade" ? "mm-active" : ""}`}
                  to="/regular-trade"
                >
                 Traditional Trade
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "smart-trade" ? "mm-active" : ""}`}
                  to="/smart-trade"
                >
                  Smart Trade
                </Link>
              </li>              
              <li>
                <Link
                  className={`${path === "terminal" ? "mm-active" : ""}`}
                  to="/terminal"
                >
                  Terminal
                </Link>
              </li>              
            </ul>
          </li>            
        </MM>
        <div className="book-box">
          <img src={book} alt="" />
          <Link to="javascript:void(0);">Generate Report &gt;</Link>
        </div>
        <div className="copyright">
          <p>
            <strong>Crypto Trading Dashboard</strong> © 2021 All Rights
            Reserved
          </p>
          <p className="fs-12">
            Made with <span className="heart" /> by Procezio
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
