module.exports = {
    testnet_env: {
        base_url: 'https://testnet.binance.vision',
        api_key: localStorage.getItem('keytestnet'),
        api_secret: localStorage.getItem('secrettestnet'),
    },

    live_env: {
        base_url: 'https://api.binance.com',
        api_key: localStorage.getItem('keylive'),
        api_secret: localStorage.getItem('secretlive'),
    },

    endpoints: {
        exchangeInfo: '/exchangeInfo',
        buyOrder: '/order',
        allTickers: '/allTickers',
        tickerPrice: '/allTickers',
        ticker24Change: '/ticker24Change',
        accInfo: '/account',
        getAllTrades: '/allOrders'
    },

    defaultShow: 'P'
}