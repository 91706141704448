import React, { useEffect, useState } from "react";
import { logout, isLogin } from "../../middleware/auth";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
// import profile from "../../../images/profile/pic1.jpg";
import profile from "../../../images/profile/coin.jpg";
import avatar from "../../../images/avatar/1.jpg";
import swal from "sweetalert";
import swalMessage from "@sweetalert/with-react";
import Toggle from "../../components/Noui Slider/Toggle";

import { Row, Col, Card, Button, Dropdown, ButtonGroup } from "react-bootstrap";

const Header = ({ onNote }) => {
  const [state, setState] = useState(false);

  useEffect(() => setState(isLogin()), [onNote]);
  const handleLogout = () => {
    logout();
    setState(false);
  };

  const usrid = localStorage.getItem("id");
  const usrname = localStorage.getItem("name");
  const defaultAcocunt = localStorage.getItem("defaultAcocunt");
  const [toggleAccount, setToggleAccount] = useState(false);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
  // console.log(finalName.join(" "));

  function handleToggleAccount(e) {
    //alert(e.target.checked);
    if (e.target.checked == false) {
      localStorage.setItem("defaultAccount", "P");
      swalMessage(
        <div>
          <h5>You have choosen to do Paper Trading</h5>
        </div>
      );
    } else {
      localStorage.setItem("defaultAccount", "L");
      swalMessage(
        <div>
          <h5>You are doing Live Trading now</h5>
        </div>
      );
    }
    console.log(
      "defaultAccount@Toggleing: " + localStorage.getItem("defaultAccount")
    );
  }
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li className="nav-item">
                <div className="input-group search-area d-xl-inline-flex d-none">
                  <div className="input-group-append">
                    <label className="fs-14 text-black pr-2">
                      Paper Trading{" "}
                    </label>
                  </div>
                </div>
                <div className="input-group search-area d-xl-inline-flex d-none">
                  <div className="input-group-append">
                    <div className="custom-control custom-switch toggle-switch text-left mr-4 mb-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch11"
                        onChange={(e) => {
                          setToggleAccount(e.target.checked);
                          handleToggleAccount(e);
                        }}
                      />
                      <label
                        className="custom-control-label fs-14 text-black pr-2"
                        htmlFor="customSwitch11"
                      >
                        Live{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </li>

              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  // href="#"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img src={profile} width={20} alt />
                  <div className="header-info">
                    <span>{usrname}</span>
                    {/* <small>Normal User</small> */}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="mt-2">
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>
                  <Link
                    className="dropdown-item ai-icon"
                    onClick={() => handleLogout()}
                    to="/"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ml-2">Logout </span>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
