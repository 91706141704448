import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Axios from '../constants/apicalling';
import URLs from '../constants/urls.json';
import swal from "sweetalert";

// image
import logo from "../../images/logo-full.png";

import { isLogin, logout } from '../middleware/auth';

const Register = ({ history }) => {
  const [username, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [state, setState] = useState(false)

  useEffect(() => setState(isLogin()), [history])

  const submitHandler = (e) => {
        e.preventDefault();
        const data = {
          username: username,
          useremail: email,
          userpass: password
      }

      Axios.post(URLs.register, data).then((res) => {
          console.log(JSON.stringify(res));
          swal("Good job!", JSON.stringify(res), "success");
          handleLogout();
          // if(res.data.status_code===1)
          // {
          //     swal("Good job!", res.data.status_msg, "success")              
          //     handleLogout();
          // }
          // else
          // {
          //     swal("Oops", "Something went wrong!"+res.data.status_msg, "error")
          //     history.push("/page-register");
          // }
      }).catch((e) => {
          console.log('Error in calling new registration= '+e);
          swal("Registration Problem!", 'Could not register the user', "error")
          handleLogout();
      })
  };

  const handleLogout = () => {
    logout();
    setState(false);
    history.push("/");
  }


  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleUserNameChange(e) {
    setUser(e.target.value);
  }

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/">
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Username</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="username"
                          required
                          onChange={handleUserNameChange}
                          name="username"
                          id="username"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="hello@example.com"
                          required
                          onChange={handleEmailChange}
                          name="useremail"
                          id="useremail"
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1 ">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          defaultValue="Password"
                          required
                          onChange={handlePasswordChange}
                          name="userpass"
                          id="userpass"
                        />
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Sign me up
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p className="">
                        Already have an account?{" "}
                        <Link className="text-primary" to="/">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
