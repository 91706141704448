import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
const MarketCapital = () => {
    return (
            <CryptoCurrencyMarket 
                colorTheme="light" 
                width="100%" 
                height={400}
            />   
            // <h6>Under Construction</h6>      
    )
};

export default MarketCapital;




