import axios from 'axios'

const bnnCfg = require('../../config/binance.js');

const crypto = require('crypto');

var defaultAccount = localStorage.getItem('defaultAccount');
  console.log('defaultAccount@getAccInfo: '+defaultAccount);
  const keys = {
    'akey' : defaultAccount=='P' ? bnnCfg.testnet_env.api_key: bnnCfg.live_env.api_key,
    'skey' : defaultAccount=='P' ? bnnCfg.testnet_env.api_secret:bnnCfg.live_env.api_secret,
  }
  const baseURL = defaultAccount=='P' ? bnnCfg.testnet_env.base_url: bnnCfg.live_env.base_url;

let allSymbols = [];

export default async function getAccInfo() {
  console.log('Secret@getAccInfo: '+keys['skey']);
  console.log('Key@getAccInfo: '+keys['akey']);

  var currentTimeInMilliseconds=Date.now();
  var dataQueryString = "timestamp="+currentTimeInMilliseconds;
    
    var signature = crypto.createHmac('sha256', keys['skey']).update(dataQueryString).digest('hex');

    const options = {
      url: bnnCfg.endpoints.accInfo + '?'+ dataQueryString + '&signature=' + signature,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
				'X-MBX-APIKEY': keys['akey'],
			},
    };

    var valuesArray = '';
    await axios(options)
      .then(response => { 
        //console.log("Got the response");
        valuesArray = response;
        //console.log('Response: '+valuesArray);
        //for (const [key, value] of Object.entries(response)) {
          //if(key=='BTCUSDT' || key=='ETHUSDT' || key=='LTCUSDT' || key=='DASHUSDT')
            //console.log(`${key}: ${value}`);
        //}
      })
      .catch(error => {alert('Error: '+error)} );

      return valuesArray;
  }

  async function getFreeLocked(pair){ 
      let valuesArray = [];
      const pair_array = pair.split('/');
      let pair1 = pair_array[0];
      let pair2 = pair_array[1];

      let accInfo = await getAccInfo();
      var stage1 = JSON.stringify(accInfo);
      var stage2 = JSON.parse(stage1);
      var res = [];              
      for(var i in stage2)
      {
          res.push(stage2[i]);
      }
      if(typeof res[0] === 'undefined' || res[0] === null){
          //wait only
      }
      else{
        for(var i in res[0].balances){ //BTCUSDT--> BTC Base Assets & USDT Quote Asset
          if(res[0].balances[i].asset==pair1)
          {
              valuesArray.push('"baseAsset":"'+pair1+'","free":"'+parseFloat(res[0].balances[i].free)+'","locked":"'+parseFloat(res[0].balances[i].locked)+'"');
              //console.log(res[0].balances[i].asset+" == "+parseFloat(res[0].balances[i].free)+" == "+parseFloat(res[0].balances[i].locked));
          }
          else if(res[0].balances[i].asset==pair2)
          {
              valuesArray.push('"quoteAsset":"'+pair2+'","free1":"'+parseFloat(res[0].balances[i].free)+'","locked1":"'+parseFloat(res[0].balances[i].locked)+'"');
              //console.log(res[0].balances[i].asset+" == "+parseFloat(res[0].balances[i].free)+" == "+parseFloat(res[0].balances[i].locked));
          }
          
        }
      }
      return '{'+valuesArray+'}';
  }

  async function getAllTrades() {
    var allsymbols = [
                          'BTCUSDT',
                          'BNBUSDT',
                          'ETHUSDT',
                          'LTCUSDT',
                          'TRXUSDT',
                          'XRPUSDT',
                          'BTCBUSD',
                          'BNBBUSD',
                          'ETHBUSD',
                          'LTCBUSD',
                          'TRXBUSD',
                          'XRPBUSD',
                          'BNBBTC',
                          'ETHBTC',
                          'LTCBTC',
                          'TRXBTC',
                          'XRPBTC',
                          'LTCBNB',
                          'TRXBNB',
                          'XRPBNB'
                    ];
    var valuesArray = [];
    for (var i = 0; i < allsymbols.length; i++) {
        //console.log('Getting records for '+allsymbols[i]);
        var temp = await getSymbolTrade('symbol='+allsymbols[i]);
        valuesArray.push(temp);
    }
    //console.log('value array: '+JSON.stringify(valuesArray));
    return valuesArray;
  }

  async function getSymbolTrade(tradingPair) {

    var currentTimeInMilliseconds=Date.now();

    var dataQueryString = tradingPair+"&timestamp="+currentTimeInMilliseconds;
    var signature = crypto.createHmac('sha256', keys['skey']).update(dataQueryString).digest('hex');

    const options = {     
        url: bnnCfg.endpoints.getAllTrades + "?" + dataQueryString + '&signature=' + signature,  
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-MBX-APIKEY': keys['akey'],
        },
    }; 
    var valuesArray = '';
    await axios(options)
      .then(response => {         
        for (const [key, value] of Object.entries(response)) {
          if(key=='data' && value.length>0)
              valuesArray = value;
          //console.log(`${key}: ${value}`);
        }
      })
      .catch(error => {console.log('Error@getAllTrades: '+error)});
    return valuesArray;
  }

  

  export { 
    getAllTrades, getFreeLocked
  };