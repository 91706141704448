import axios from 'axios'

const bnnCfg = require('../../config/binance.js');

export default async function doSimpleTrade(tradeVals) {

    const crypto = require('crypto');

    var currentTimeInMilliseconds=Date.now();

    var defaultAccount = localStorage.getItem('defaultAccount');
    console.log('Default Account in doSimpleTrade :'+defaultAccount);
    
    const keys = {
      'akey' : defaultAccount=='P' ? bnnCfg.testnet_env.api_key: bnnCfg.live_env.api_key,
      'skey' :defaultAccount=='P' ? bnnCfg.testnet_env.api_secret:bnnCfg.live_env.api_secret,
    }    
    const baseURL = defaultAccount=='P' ? bnnCfg.testnet_env.base_url: bnnCfg.live_env.base_url;

    var dataQueryString = tradeVals+"&timestamp="+currentTimeInMilliseconds;

    var signature = crypto.createHmac('sha256', keys['skey']).update(dataQueryString).digest('hex');
    //console.log('OMG: '+bnnCfg.endpoints.buyOrder + "?" + dataQueryString + '&signature=' + signature);
    const options = {
      // url: 'https://testnet.binance.vision/api/v3/order?' + dataQueryString + '&signature=' + signature,
       url: bnnCfg.endpoints.buyOrder + "?" + dataQueryString + '&signature=' + signature,
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
				'X-MBX-APIKEY': keys['akey'],
			},
    };

    var valuesArray = '';
    await axios(options)
      .then(response => { 
        console.log("Got the response");
        valuesArray = response;
        for (const [key, value] of Object.entries(response)) {
          //if(key=='BTCUSDT' || key=='ETHUSDT' || key=='LTCUSDT' || key=='DASHUSDT')
            console.log(`${key}: ${value}`);
        }
      })
      .catch(error => {console.log('Error@SimpleTrade: '+error)} );

      return valuesArray;
  }