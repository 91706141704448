import { TickerTape  } from "react-ts-tradingview-widgets";

const TickerTapeDtls = () => {
    return (
            <TickerTape 
                colorTheme="light" 
            />    
    )
};

export default TickerTapeDtls;
