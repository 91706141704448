import axios from 'axios'
import getAuthClient, {publicClient} from '../constants/binance'

let allSymbols = [];

export default async function getAccInfo() {  
    var client = getAuthClient();
    var accDtls = null;
    console.log('Got the client.: '+JSON.stringify(client));
    try{
          accDtls = await client.accountInfo();
    }catch(err) {
      console.log('Error@getAccInfo_New '+err); // Failed to fetch
    }
    console.log('Account Details are : '+accDtls);
    return accDtls;
  }

  async function getFreeLocked(pair){ 
      let valuesArray = [];
      // const pair_array = pair.split('/');
      // let pair1 = pair_array[0];
      // let pair2 = pair_array[1];

      // let accInfo = await getAccInfo();
      // var stage1 = JSON.stringify(accInfo);
      // var stage2 = JSON.parse(stage1);
      // var res = [];              
      // for(var i in stage2)
      // {
      //     res.push(stage2[i]);
      // }
      // if(typeof res[0] === 'undefined' || res[0] === null){
      //     //wait only
      // }
      // else{
      //   for(var i in res[0].balances){ //BTCUSDT--> BTC Base Assets & USDT Quote Asset
      //     if(res[0].balances[i].asset==pair1)
      //     {
      //         valuesArray.push('"baseAsset":"'+pair1+'","free":"'+parseFloat(res[0].balances[i].free)+'","locked":"'+parseFloat(res[0].balances[i].locked)+'"');
      //         //console.log(res[0].balances[i].asset+" == "+parseFloat(res[0].balances[i].free)+" == "+parseFloat(res[0].balances[i].locked));
      //     }
      //     else if(res[0].balances[i].asset==pair2)
      //     {
      //         valuesArray.push('"quoteAsset":"'+pair2+'","free1":"'+parseFloat(res[0].balances[i].free)+'","locked1":"'+parseFloat(res[0].balances[i].locked)+'"');
      //         //console.log(res[0].balances[i].asset+" == "+parseFloat(res[0].balances[i].free)+" == "+parseFloat(res[0].balances[i].locked));
      //     }
          
      //   }
      // }
      return '{'+valuesArray+'}';
  }

  async function getAllTrades() {
    var valuesArray = [];
    var allsymbols = [
                          'BTCUSDT',
                          'BNBUSDT',
                          'ETHUSDT',
                          'LTCUSDT',
                          'TRXUSDT',
                          'XRPUSDT',
                          'BTCBUSD',
                          'BNBBUSD',
                          'ETHBUSD',
                          'LTCBUSD',
                          'TRXBUSD',
                          'XRPBUSD',
                          'BNBBTC',
                          'ETHBTC',
                          'LTCBTC',
                          'TRXBTC',
                          'XRPBTC',
                          'LTCBNB',
                          'TRXBNB',
                          'XRPBNB'
                    ];
    
    // for (var i = 0; i < allsymbols.length; i++) {
    //     //console.log('Getting records for '+allsymbols[i]);
    //     var temp = await getSymbolTrade('symbol='+allsymbols[i]);
    //     valuesArray.push(temp);
    // }
    //console.log('value array: '+JSON.stringify(valuesArray));
    return valuesArray;
  }

  async function getSymbolTrade(tradingPair) {
    var valuesArray = '';
    // var currentTimeInMilliseconds=Date.now();

    // var dataQueryString = tradingPair+"&timestamp="+currentTimeInMilliseconds;
    // var signature = crypto.createHmac('sha256', keys['skey']).update(dataQueryString).digest('hex');

    // const options = {     
    //     url: bnnCfg.endpoints.getAllTrades + "?" + dataQueryString + '&signature=' + signature,  
    //     method: 'GET',
    //     headers: {
    //       'Access-Control-Allow-Origin': '*',
    //       'Content-Type': 'application/json',
    //       'X-MBX-APIKEY': keys['akey'],
    //     },
    // }; 
    
    // await axios(options)
    //   .then(response => {         
    //     for (const [key, value] of Object.entries(response)) {
    //       if(key=='data' && value.length>0)
    //           valuesArray = value;
    //       //console.log(`${key}: ${value}`);
    //     }
    //   })
    //   .catch(error => {console.log('Error@getAllTrades: '+error)});
    return valuesArray;
  }

  

  export { 
    getAllTrades, getFreeLocked
  };