import Binance from 'binance-api-node'
const bnnCfg = require('../../config/binance');

export const publicClient = Binance();

//Authenticated client, can make signed calls
export default function getAuthClient()
{
    var defaultAccount = localStorage.getItem('defaultAccount');
    console.log('Default Account is '+(defaultAccount=='P' ? 'Paper':'Live'));
    const baseURL = defaultAccount=='P' ? bnnCfg.testnet_env.base_url: bnnCfg.live_env.base_url;

    const authClient = Binance({
        apiKey: defaultAccount=='P' ? bnnCfg.testnet_env.api_key: bnnCfg.live_env.api_key,
        apiSecret: defaultAccount=='P' ? bnnCfg.testnet_env.api_secret:bnnCfg.live_env.api_secret,
        //getTime: xxx,
      })
    return authClient;
}
