import React, { Fragment, useState, useEffect } from "react";
import { ButtonGroup, Dropdown, SplitButton } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import PageTItle from "../../layouts/PageTitle";

import Axios from '../../constants/apicalling';
import URLs from '../../constants/urls.json';
import swal from "sweetalert";

const Exchange = () => {
    const [name, setName] = useState('');
    const [keylive, setKeylive] = useState('');
    const [secretlive, setSecretlive] = useState('');
    const [keytesting, setKeytesting] = useState('');
    const [secrettesting, setSecrettesting] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();
        const data = {
          name: name,
          keylive: keylive,
          secretlive: secretlive,
          keytesting: keytesting,
          secrettesting: secrettesting,
      }
      Axios.post(URLs.exchange, data).then((res) => {
          console.log(JSON.stringify(res));
          swal("Good job!", JSON.stringify(res), "success");
          if(res.data.status_code===1)
          {
              swal("Good job!", res.data.status_msg, "success")    
          }
          else
          {
              swal("Oops", "Something went wrong!"+res.data.status_msg, "error")              
          }
          clearForm();
      }).catch((e) => {
          console.log('Error in calling new exchange= '+e);
          swal("Exchange Problem!", 'Could not register the exchange', "error")
      })
  };

  function clearForm() {
    setName('');
    setKeylive('');
    setSecretlive('');
    setKeytesting('');
    setSecrettesting('');
  }

  return (
    <Fragment>
      <PageTItle
        activeMenu="Exchange"
        motherMenu="My Exchange"
        pageContent="Connect Exchange"
      />

      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            {/* <div className="card-header">
              <h4 className="card-title">Select Exchange</h4>
            </div> */}
            <div className="card-body">
              <form onSubmit={(e) => submitHandler(e)}>
                <div className="basic-form">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Exchange</label>
                    <div className="col-sm-9">
                      <select
                        defaultValue={"option"}
                        className="form-control form-control-sm"
                      >
                        <option value={"binance"}>Binance</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        id="name"
                        onChange={(e) => {setName(e.target.value);}}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      API Key Live
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="API Key Live"
                        name="keylive"
                        id="keylive"
                        onChange={(e) => {setKeylive(e.target.value)}}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      API Secret Live
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="API Secret Live"
                        name="secretlive"
                        id="secretlive"
                        onChange={(e) => {setSecretlive(e.target.value)}}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      API Key Testnet
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="API Key Testnet"
                        name="keytesting"
                        id="keytesting"
                        onChange={(e) => {setKeytesting(e.target.value)}}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      API Secret Testnet
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="API Secret Testnet"
                        name="secrettesting"
                        id="secrettesting"
                        onChange={(e) => {setSecrettesting(e.target.value)}}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-10">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Exchange;
