/* Authetifacation actions */
// import { message } from 'antd';
import swal from "sweetalert";

import Axios from '../constants/apicalling';
import URLs from '../constants/urls.json';

var CryptoJS = require("crypto-js");

// LOGIN
export const login = (history, d) => {    

    Axios.post(URLs.check_login, d).then((res) => {
        var id = res.data.id;
        var name = res.data.name;
        var status = res.data.status;
        var message = res.data.message;
        var apiDetails = res.data.apiDtls;        

        // for (const [key, value] of Object.entries(res.data)) {  console.log(`${key}: ${value}`); }

        if(res.data.status=='SUCCESS')
        {
            //Object.assign(d, {id: id});// Adding into existing d i.e. java script object
            //Object.assign(d, {name: name});// Adding into existing d i.e. java script object
            localStorage.setItem('id', id);
            localStorage.setItem('name', name);
            localStorage.setItem('auth', d);
            
            console.log('Login Success: '+d);
            
            var bytes = CryptoJS.AES.decrypt(apiDetails, 'zamzam123@my-secret-key');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            localStorage.setItem('keylive', decryptedData.key_live);
            localStorage.setItem('secretlive', decryptedData.secret_live);
            localStorage.setItem('keytestnet', decryptedData.key_testnet);
            localStorage.setItem('secrettestnet', decryptedData.secret_testnet);
            localStorage.setItem('defaultAccount', 'P');
            // console.log('Setting API Details Here....');
            // console.log(decryptedData.key_live);
            // console.log(decryptedData.secret_live);
            // console.log(decryptedData.key_testnet);
            // console.log(decryptedData.secret_testnet);
            history.push('/dashboard');
        }
        else
        {
            swal(status, message, "error")
            history.push("/");
        }
    }).catch((e) => {
        console.log(e);
        swal("Fatal Error!", 'Error is '+e , "error");
    })
}

// LOGOUT
export const logout = () => {
    localStorage.removeItem('auth')
    //message.success('Logout Success')
    console.log('Logout Success');
}

// LOGIN STATUS
export const isLogin = () => {
    if (localStorage.getItem('auth')) return true;
    return false;
}
